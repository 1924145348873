import React, { FC } from 'react'
import { AuthRole } from '@persol-epdndo/base-shared'
import { PrivatePageNavigation } from '../navigations/PrivatePageNavigation'
import { PageProps } from './_app'
import NotFound from '../components/NotFound'

export const NotFoundPage: FC<PageProps> = (): JSX.Element => {
  return <NotFound />
}

NotFoundPage.defaultProps = {
  navigations: <PrivatePageNavigation />,
  authProps: {
    roles: AuthRole.LoginUser,
  },
}

export default NotFoundPage
